<template>
  <div class="equipment_box">
    <div class="eq_form">
      <div class="form_item">
        <div class="lable">设备验证码</div>
        <div class="input_inline">
          <input
            type="text"
            v-model="form.num"
            placeholder="请输入设备验证码"
          />
        </div>
      </div>

      <div class="form_item">
        <div class="lable">所在小区</div>
        <div class="input_inline">
          <div class="ser_box">
            <input
              type="text"
              v-model="form.name"
              placeholder="请输入所在小区"
              @focus="cell_ipt_focus"
              @blur="cell_ipt_blur"
              @input="get_cellList"
            />

            <div class="ser_list" v-if="name_flag">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="get_cellList"
              >
                <van-cell
                  class="cell_li"
                  v-for="item in cell_list"
                  :key="item"
                  @click="check_cell(item)"
                >
                  {{ item.name }}
                </van-cell>
              </van-list>
            </div>
          </div>
        </div>
      </div>

      <div class="form_item">
        <div class="lable">充电桩位置</div>
        <div class="input_inline">
          <input
            type="text"
            v-model="form.location"
            placeholder="请输入充电桩位置"
          />
        </div>
      </div>

      <div class="form_item">
        <div class="lable">详细地址</div>
        <div class="input_inline">
          <div class="tc_map_box">
            <input
              type="text"
              v-model="form.address_detail"
              placeholder="请选择详细地址"
              readonly
              @click="open_map"
            />

            <i class="iconfont icon-dingwei-1"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_box">
      <van-button class="btn again_btn" @click="again_check_qrcode">
        重新扫码
      </van-button>

      <van-button class="btn" @click="submit_btn"> 提交 </van-button>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "@/api/user";
import { getAdminTokens, _debounce } from "@/libs/util";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      qr_id: "",
      form: {
        num: "", // 设备验证码
        name: "", // 所在小区名
        village_id: "", // 所在小区名
        location: "", // 充电桩位置
        address_detail: "", // 详细位置
        longitude: "", // 经度
        latitude: "", // 纬度
      },
      cell_list: [],
      loading: false,
      finished: false,
      name_flag: false, // 小区列表 展示 隐藏
    };
  },
  created() {
    let token = getAdminTokens();
    if (!token) {
      this.$router.push({ name: "admin_login" });
    }

    let query = this.$route.query;
    console.log("query>>>>>", query);

    if (query) {
      this.qr_id = query.qr_id;

      if (query.latng) {
        let arr = query.latng.split(",");
        this.form.address_detail = query.addr;
        this.form.latitude = arr[0];
        this.form.longitude = arr[1];
      }
    }

    let info = JSON.parse(sessionStorage.getItem("beforeInfo"));
    console.log("info>>>", info);
    if (info) {
      this.form.num = info.num;
      this.form.name = info.name;
      this.form.village_id = info.village_id;
      this.form.location = info.location;
    }
  },
  methods: {
    cell_ipt_focus() {
      this.name_flag = true;
    },
    cell_ipt_blur() {
      setTimeout(() => {
        this.name_flag = false;
      }, 50);
    },
    check_cell(row) {
      console.log(row);
      this.name_flag = false;
      this.form.name = row.name;
      this.form.village_id = row.id;
    },
    // 小区地址 列表
    get_cellList() {
      // 异步更新数据
      getData({
        url: `api/maintain/index/village?name=${this.form.name}`,
        data: {
          name: this.form.name,
        },
        is_admin: true,
      }).then((res) => {
        let data = res.data;
        console.log(data);
        if (data.code === 200) {
          this.loading = false;
          this.finished = true;
          this.cell_list = data.data.list;
        }
      });
    },
    open_map() {
      let info = {
        num: this.form.num, // 设备验证码
        name: this.form.name, // 所在小区名
        village_id: this.form.village_id, // 所在小区名
        location: this.form.location, // 充电桩位置
      };
      sessionStorage.setItem("beforeInfo", JSON.stringify(info));

      let backurl = window.location.href.split("#")[0];
      window.location.href = `https://apis.map.qq.com/tools/locpicker?search=1&type=0&backurl=${backurl}&key=5XKBZ-X6FKX-4PM4Z-7E5WI-Q2ZCS-EBBSQ&referer=charger`;

      // this.$toast("打开腾讯地图");
      // getData({
      //   url: "api/get_js_config",
      //   data: {
      //     url: backurl, // url必须是本页面的地址
      //   },
      // }).then((res) => {
      //   console.log(res);
      //   let data = res.data.data;
      //   wx.config({
      //     debug: false,
      //     appId: data.appId,
      //     timestamp: data.timestamp,
      //     nonceStr: data.nonceStr,
      //     signature: data.signature,
      //     jsApiList: ["getLocation"],
      //   });
      //   wx.error(function (res) {
      //     alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
      //   });

      //   wx.ready(function () {
      //     // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
      //     // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
      //     wx.checkJsApi({
      //       // 判断当前客户端版本是否支持指定JS接口
      //       jsApiList: ["getLocation"],
      //       success: function (res) {
      //         if (res.checkResult.getLocation === true) {
      //           wx.getLocation({
      //             type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      //             success: function (res) {
      //               let latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
      //               let longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。

      //               window.location.href = `https://apis.map.qq.com/tools/locpicker?search=1&type=0&backurl=${backurl}&key=5XKBZ-X6FKX-4PM4Z-7E5WI-Q2ZCS-EBBSQ&referer=charger&coord=${latitude},${longitude}`;
      //             },
      //           });
      //         } else {
      //           alert("抱歉，当前客户端版本不支持获取地理位置");
      //         }
      //       },
      //       fail: function (res) {
      //         // 检测getNetworkType该功能失败时处理
      //         alert("检测该功能失败" + res);
      //       },
      //     });
      //   });
      //   /* 处理失败验证 */
      //   wx.error(function (res) {
      //     // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
      //     alert("配置验证失败: " + res.errMsg);
      //   });
      // });
    },
    listen() {
      window.addEventListener(
        "message",
        function (event) {
          // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
          var loc = event.data;
          if (loc && loc.module == "locationPicker") {
            //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
            console.log("location", loc); // 获取的选中的地址数据
          }
          return false;
        },
        false
      );
    },
    // 重新扫码
    again_check_qrcode() {
      getData({
        url: "api/get_js_config",
        data: {
          url: window.location.href.split("#")[0],
        },
      }).then((res) => {
        console.log(res);
        let data = res.data.data;
        wx.config({
          debug: false,
          appId: data.appId + "",
          timestamp: data.timestamp,
          nonceStr: data.nonceStr + "",
          signature: data.signature + "",
          jsApiList: ["scanQRCode", "checkJsApi"], // 必填,需要使用的JS接口列表
        });
        wx.error(function (res) {
          alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
        });

        wx.ready(function () {
          // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
          // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
          wx.checkJsApi({
            // 判断当前客户端版本是否支持指定JS接口
            jsApiList: ["scanQRCode"],
            success: function (res) {
              // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
              if (res.checkResult.scanQRCode === true) {
                wx.scanQRCode({
                  // 微信扫一扫接口
                  desc: "scanQRCode desc",
                  needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                  scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                  success: function (res) {
                    console.log(res);

                    this.$router.push({ name: "admin_equipment_msg" });
                    this.$toast("扫码成功");
                  },
                });
              } else {
                alert("抱歉，当前客户端版本不支持扫一扫");
              }
            },
            fail: function (res) {
              // 检测getNetworkType该功能失败时处理
              alert("检测getNetworkType该功能失败" + res);
            },
          });
        });
        /* 处理失败验证 */
        wx.error(function (res) {
          // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
          alert("配置验证失败: " + res.errMsg);
        });
      });
    },
    // 提交 绑定
    submit_btn: _debounce(function () {
      // this.$router.push({ name: "admin_binding_list" });
      let num = this.form.num;
      let village_id = this.form.village_id;
      let location = this.form.location;
      let address_detail = this.form.address_detail;
      let longitude = this.form.longitude;
      let latitude = this.form.latitude;

      if (!num) {
        this.$toast("请输入设备验证码");
        return;
      } else if (!village_id) {
        this.$toast("请选择所在小区");
        return;
      } else if (!location) {
        this.$toast("请输入充电桩位置");
        return;
      } else if (!address_detail) {
        this.$toast("请选择详细地址");
        return;
      }

      postData({
        url: `api/maintain/index/register`,
        data: {
          id: this.qr_id,
          num,
          village_id,
          location,
          address_detail,
          longitude,
          latitude,
        },
        is_admin: true,
      }).then((res) => {
        let data = res.data;
        console.log(data);
        if (data.code === 200) {
          this.$toast(data.data.msg);
          this.$router.push({ name: "admin_binding_list" });
        } else if (data.code === 422) {
          this.$toast(data.data.msg);
        }
      });
    }),
  },
  beforeCreate() {
    // 改变背景颜色
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#fff!important");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent;

    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // XXX: 修复iOS版微信HTML5 History兼容性问题

    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace

      location.assign(to.fullPath);
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.equipment_box {
  // min-height: 100vh;
  padding: 20px 0;
  padding-bottom: calc(20px + constant(safe-area-inset-bottom));

  // height: 100vh;
  background-color: #fff !important;
  text-align: center;

  .eq_form {
    padding: 10px 13px 30px;

    .form_item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .lable {
        margin-right: 15px;
        width: 75px;
        text-align: left;
        font-size: 15px;
        line-height: 21px;
        // line-height: 1;
        flex-shrink: 0;
      }

      .input_inline {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        line-height: 40px;

        input {
          padding: 0 20px;
          width: 100%;
          height: 100%;
          line-height: 40px;
          font-size: 14px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
        }
      }

      .ser_box {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;

        .ser_list {
          position: absolute;
          top: 45px;
          left: 0;
          width: 100%;
          height: 300px;
          background-color: #fff;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          overflow-y: scroll;
          z-index: 1;

          .cell_li {
            font-size: 15px;
            color: #333;
          }
        }
      }

      .tc_map_box {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;

        input {
          padding-right: 40px;
        }

        .iconfont {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          font-size: 16px;
          color: #999999;
        }
      }
    }
  }

  .btn_box {
    position: absolute;
    left: 0;
    // bottom: 20px;
    bottom: calc(20px + constant(safe-area-inset-bottom));
    bottom: calc(20px + env(safe-area-inset-bottom));

    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      width: 172px;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      border: 1px solid #3dc48d;
      background-color: #3dc48d;
      border-radius: 22px;
    }

    .again_btn {
      margin-right: 6px;
      background-color: #fff;
      color: #3dc48d;
    }
  }
}
</style>